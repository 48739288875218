<template>
  <div>
    <Progress state="5" />

    <div>
      <h2 class="text-center font-bold text-2xl text-gray-600">
        Vielen Dank {{ data.delivery_title }} {{ data.delivery_lastname }}!<br />
        Wir haben Ihre Bestellung erhalten.
      </h2>
      <div class="grid md:grid-cols-2 gap-x-12 gap-y-6">
        <div class="mt-6 p-8 bg-white border border-gray-100 rounded-lg shadow">
          <h3 class="font-bold text-gray-600">Wir schicken Ihre Box an folgende Adresse:</h3>
          <ul class="list-none">
            <li>{{ data.delivery_firstname }} {{ data.delivery_lastname }}</li>
            <li>{{ data.delivery_street }}</li>
            <li>{{ data.delivery_plz }} {{ data.delivery_city }}</li>
          </ul>
        </div>
        <div class="mt-6 p-8">
          <h3 class="font-bold text-gray-600">Ihre Bestelldaten zum Herunterladen</h3>
          <div class="mt-4">
            <button class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500" >
              <a @click="download()" class="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-5 h-5 text-white">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                </svg>
                <span class="ml-2">PDF herunterladen</span>
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Progress from "./Progress.vue";
import shop from "../api/shop";

export default {
  components: { Progress },
  data: () => ({}),
  methods: {
    download() {
      shop.downloadOrder(this.token, (data) => {
        var fileURL = window.URL.createObjectURL(new Blob([data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", "Bestellung.pdf");
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
  },
  computed: {
    ...mapGetters("user", {
      token: "token",
      data: "data",
    }),
  },
};
</script>
